// Our main CSS
import '../css/app.css'
// layzyload and/or play/pause inline videos when they are in/out of view
const autoplaying_videos = document.querySelectorAll(
  'video[playsinline]'
) as NodeListOf<HTMLVideoElement>

if (autoplaying_videos && autoplaying_videos.length) {
  import('./modules/inline-videos').then((module) => {
    module.default(autoplaying_videos)
  })
}

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

const videoWrapper = document.querySelectorAll('.videoWrapper')
if (videoWrapper && videoWrapper !== null) {
  videoWrapper.forEach((wrapper) => {
    import('./Components/index.ts')
      .then((module) => {
        module.default(wrapper as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
        // main.textContent = err.message;
      })
  })
}

const accordionWrapper = document.querySelectorAll('.accordionWrapper')
if (accordionWrapper && accordionWrapper !== null) {
  accordionWrapper.forEach((wrapper) => {
    import('./Components/index.ts')
      .then((module) => {
        module.default(wrapper as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
        // main.textContent = err.message;
      })
  })
}

const contactWrapper = document.querySelectorAll('.contactWrapper')
if (contactWrapper && contactWrapper !== null) {
  contactWrapper.forEach((wrapper) => {
    import('./Components/index.ts')
      .then((module) => {
        module.default(wrapper as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
        // main.textContent = err.message;
      })
  })
}

const mobileMenuWrapper = document.querySelectorAll('.mobileMenuWrapper')
if (mobileMenuWrapper && mobileMenuWrapper !== null) {
  mobileMenuWrapper.forEach((wrapper) => {
    import('./Components/index.ts')
      .then((module) => {
        module.default(wrapper as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
        // main.textContent = err.message;
      })
  })
}

const menuButtonWrapper = document.querySelectorAll('.menuButtonWrapper')
if (menuButtonWrapper && menuButtonWrapper !== null) {
  menuButtonWrapper.forEach((wrapper) => {
    import('./Components/index.ts')
      .then((module) => {
        module.default(wrapper as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
        // main.textContent = err.message;
      })
  })
}

const singleContactFormWrapper = document.querySelectorAll('.singleContactFormWrapper')
if (singleContactFormWrapper && singleContactFormWrapper !== null) {
  singleContactFormWrapper.forEach((wrapper) => {
    import('./Components/index.ts')
      .then((module) => {
        module.default(wrapper as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
        // main.textContent = err.message;
      })
  })
}

const animatedCardWrapper = document.querySelectorAll('.animatedCardWrapper')
if (animatedCardWrapper && animatedCardWrapper !== null) {
  animatedCardWrapper.forEach((wrapper) => {
    import('./Components/index.ts')
      .then((module) => {
        module.default(wrapper as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
        // main.textContent = err.message;
      })
  })
}

const TestimonialSliderWrapper = document.querySelectorAll('.TestimonialSliderWrapper')
if (TestimonialSliderWrapper && TestimonialSliderWrapper !== null) {
  TestimonialSliderWrapper.forEach((wrapper) => {
    import('./Components/index.ts')
      .then((module) => {
        module.default(wrapper as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
        // main.textContent = err.message;
      })
  })
}

const LogoSliderWrapper = document.querySelectorAll('.LogoSliderWrapper')
if (LogoSliderWrapper && LogoSliderWrapper !== null) {
  LogoSliderWrapper.forEach((wrapper) => {
    import('./Components/index.ts')
      .then((module) => {
        module.default(wrapper as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
        // main.textContent = err.message;
      })
  })
}

const AllNewsWrapper = document.querySelectorAll('.AllNewsWrapper')
if (AllNewsWrapper && AllNewsWrapper !== null) {
  AllNewsWrapper.forEach((wrapper) => {
    import('./Components/index.ts')
      .then((module) => {
        module.default(wrapper as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
        // main.textContent = err.message;
      })
  })
}

const languageSelectionWrapper = document.querySelectorAll('.languageSelectionWrapper')
if (languageSelectionWrapper && languageSelectionWrapper !== null) {
  languageSelectionWrapper.forEach((wrapper) => {
    import('./Components/index.ts')
      .then((module) => {
        module.default(wrapper as HTMLElement)
      })
      .catch((err) => {
        console.log(err)
        // main.textContent = err.message;
      })
  })
}

/**
 * 🦄: Now do your magic.
 */
